.ImpactRatingProportionsChart {
  display: flex;
  width: 100%;
  height: 56px;
  position: relative;
}

.ImpactRatingProportionsChart_RatingSections {
  display: flex;
  flex-direction: row;
  gap: 2px;
  width: 100%;
  position: absolute;
  top: 8px;
  z-index: 1;
}

.ImpactRatingProportionsChart_RatingSections > span:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ImpactRatingProportionsChart_RatingSections > span:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ImpactRatingProportionsChart_RatingSection {
  height: 20px;
  align-content: center;
  text-align: center;
  border-radius: inherit;
  margin-top: 2px;
}

.ImpactRatingProportionsChart_RatingSection:hover {
  height: 24px;
  position: relative;
  z-index: 2;
  transform: translate(-2px, -2px);
  cursor: default;
  width: calc(100% + 4px);
  box-shadow: 0 2px 0 2px var(--white);
}

.ImpactRatingProportionsChart_RatingSections
  > span:first-child
  > .ImpactRatingProportionsChart_RatingSection:hover {
  box-shadow: 2px 0 0 0 var(--white);
  width: calc(100% + 2px);
  transform: translate(0px, -2px);
  position: relative;
  z-index: 100;
}
.ImpactRatingProportionsChart_RatingSections
  > span:last-child
  > .ImpactRatingProportionsChart_RatingSection:hover {
  width: calc(100% + 2px);
  box-shadow: 0 0 0 2px var(--white);
  transform: translate(-2px, -2px);
}

.ImpactRatingProportionsChart_RatingSection_Tooltip {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 8px;
  padding: 0 !important;
}

.ImpactRatingProportionsChart_RatingSection_Tooltip_ImpactRating {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.ImpactRatingProportionsChart_RatingSection_Tooltip_Percentage {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
}

.ImpactRatingProportionsChart_Legend {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  z-index: 0;
}

.ImpactRatingProportionsChart_LabelledDivider {
  display: flex;
  flex-direction: column;
  color: var(--mid-grey);
  align-items: center;
  position: absolute;
  transform: translateX(-50%);
}

.ImpactRatingProportionsChart_LabelledDivider:first-child {
  align-items: start;
  transform: none;
}

.ImpactRatingProportionsChart_LabelledDivider:last-child {
  align-items: flex-end;
  transform: translateX(-100%);
}

.ImpactRatingProportionsChart_DividerSection {
  display: flex;
  flex-direction: row;
}

.ImpactRatingProportionsChart_DividerBlock {
  background-color: var(--white);
  width: 0;
  height: 16px;
  box-shadow: 0 0 0 2px var(--white);
  transform: translate(0, 12px);
}

.ImpactRatingProportionsChart_LabelledDivider:first-child
  > .ImpactRatingProportionsChart_DividerSection
  > .ImpactRatingProportionsChart_DividerBlock,
.ImpactRatingProportionsChart_LabelledDivider:last-child
  > .ImpactRatingProportionsChart_DividerSection
  > .ImpactRatingProportionsChart_DividerBlock {
  box-shadow: none;
}

.ImpactRatingProportionsChart_Divider {
  background-color: var(--interface-grey);
  width: 1px;
  height: 40px;
}
