.recipe-page-controls-buttons {
  display: grid;
  justify-items: flex-end;
  align-items: center;
}

.recipe-page-controls-buttons > .grid-item-1-2 {
  grid-row: 1;
  grid-column: 2;
}

.recipe-page-controls-buttons > .grid-item-2-1 {
  grid-row: 2;
  grid-column: 1;
}

.recipe-page-controls-buttons > .grid-item-2-2 {
  grid-row: 2;
  grid-column: 2;
}
