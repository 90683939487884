.ChangePassword__passwordStrengthIndicatorBar {
  width: 100px;
}

.ChangePassword_PasswordStrengthBar {
  opacity: 0;
  transition: opacity 0.15s ease-out;
}

.ChangePassword_PasswordStrengthBar__visible {
  opacity: 1;
  transition: opacity 0.25s ease-in;
}
