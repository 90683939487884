$stepper__step_padding: 2rem;

.stepper .list-group-item {
  padding-left: 0;
  padding-right: $stepper__step_padding;
}

.stepper .list-group-item.active {
  background-color: $list-group-hover-bg;
  color: color-yiq($list-group-hover-bg);
  border-color: $list-group-border-color;
}

.stepper .locked {
  color: black !important;
  cursor: default;
  opacity: 30%;
}

.stepper .private-icon {
  margin-right: 8px;
  margin-bottom: 2px;
  color: black !important;
  opacity: 30%;
}

.stepper .private-icon:hover {
  color: var(--foodsteps-turquoise) !important;
  opacity: 100% !important;
}
