.RadioButtonToggleGroup__ToggleButton:active,
.RadioButtonToggleGroup__ToggleButton:active:hover {
  background-color: var(--dark-turquoise);
  color: white;
}

.RadioButtonToggleGroup__ToggleButton:not(:active) {
  background-color: white;
}

.RadioButtonToggleGroup__ToggleButton:not(:active):hover {
  color: var(--foodsteps-turquoise);
}

.RadioButtonToggleGroup__ToggleButton:not(:active):not(:hover) {
  color: var(--dark-turquoise);
}

.RadioButtonToggleGroup__ToggleButton {
  box-shadow: 0 2px 10px 0 var(--drop-shadow-darker) !important;
  border: none !important;
  border-radius: 8px;
}
