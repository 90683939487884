.FoodServiceImpactSummary_Panel {
  display: flex;
  padding: 24px 40px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.FoodServiceImpactSummary_Body {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 23px;
}

.FoodServiceImpactSummary_RecipeSummary {
  display: flex;
  width: 33%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
}

.FoodServiceImpactSummary_RecipeSummary_Impacts {
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.FoodServiceImpactSummary_RecipeSummary_Equivalent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.FoodServiceImpactSummary_GhgRating {
  display: flex;
  width: 33%;
  padding-top: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.FoodServiceImpactSummary_GhgRating_Header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.FoodServiceImpactSummary_Label_Container {
  display: flex;
  width: 33%;
  justify-content: right;
}

.FoodServiceImpactSummary_Label {
  display: flex;
  padding: 16px 16px 0 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.FoodServiceImpactSummary_RecipeSummary_Equivalent_SymbolAndDataContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.FoodServiceImpactSummary_RecipeSummary_Equivalent_DataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
}

.FoodServiceImpactSummary_ImpactCategoryInlineTitleToggle {
  margin-left: 6px;
}

.FoodServiceImpactSummary_Title {
  display: flex;
  flex-direction: row;
}
