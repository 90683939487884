.AddTagModal {
  padding: 32px 40px;
}

.AddTagModal_ButtonsContainer {
  display: flex;
  gap: 16px;
}

.AddTagModal_TextField {
  width: 480px;
}

.AddTagModal_Instructions {
  padding-bottom: 24px;
}
