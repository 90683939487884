.ProductsDashboardSummary {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
}

.DashboardSummaryCard {
  background-color: var(--white);
  display: grid;
  padding: 16px 24px 12px 24px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--interface-grey);
  flex: 1 0 0;
}

.DashboardSummaryCard__Icon {
  height: 32px;
}

.DashboardSummaryCard__ValueAndUnit {
  display: flex;
  align-items: baseline;
  gap: 4px;
}
