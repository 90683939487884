/*
These colors are defined in the Foodsteps Design Library here:
https://www.figma.com/design/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Design-Library?node-id=3270-4057&node-type=frame&t=05bZCGWBo4oaIPCe-0
*/

// Main colors
$foodsteps-turquoise: rgba(0, 166, 143, 1);
$dark-turquoise: rgba(16, 103, 91, 1);
$bone: rgba(244, 244, 240, 1);

// Accent
$accent-yellow: rgba(240, 229, 43, 1);

// Gradients
$bone-to-white: linear-gradient($bone, white);

// Drop shadows
$interface-panel-dropshadow: rgba(0, 0, 0, 0.08);
$drop-shadow-darker: rgba(0, 0, 0, 0.14);
$drop-shadow-lighter: rgba(0, 0, 0, 0.08);

// Interface
$interface-grey: rgba(222, 226, 230, 1);
$mid-grey: rgba(174, 181, 188, 1);
$dark-grey: rgba(102, 102, 101, 1);
$input-border: rgba(206, 212, 218, 1);
$interface-error: rgba(254, 240, 239, 1);
$interface-attention: rgba(251, 247, 191, 1);
$light-turquoise: rgba(224, 244, 242, 1);

// State
$danger: rgba(244, 67, 54, 0.99);
$success: rgba(66, 190, 115, 0.99);
$warning: rgba(192, 174, 59, 0.99);

// Qualitative data
$brine: rgba(217, 219, 120, 1);
$mint-pastel: rgba(207, 246, 199, 1);
$wasabi: rgba(122, 153, 43, 1);
$bright-blue: rgba(51, 209, 232, 1);
$forest-green: rgba(19, 95, 36, 1);
$marine: rgba(41, 89, 204, 1);
$navy: rgba(36, 50, 158, 1);
$racing-green: rgba(0, 56, 19, 1);
$riviera: rgba(51, 169, 192, 1);
$leaf: rgba(69, 107, 41, 1);
$aqua: rgba(148, 240, 232, 1);
$olive: rgba(158, 161, 77, 1);

// Sequential data
$lavender-rose: rgba(239, 152, 225, 1);
$mauvelous: rgba(240, 135, 198, 1);
$deep-blush: rgba(239, 117, 172, 1);
$barbie: rgba(237, 99, 147, 1);
$mandy: rgba(234, 79, 122, 1);
$cerise: rgba(229, 56, 98, 1);
$maroon-flush: rgba(200, 26, 81, 1);
$marooned: rgba(177, 28, 86, 1);
$disco: rgba(154, 30, 91, 1);
$port: rgba(130, 31, 95, 1);
$plum: rgba(105, 31, 100, 1);
$grape: rgba(77, 31, 104, 1);

// Carbon rating
$very-low-A: rgba(52, 128, 65, 1);
$low-B: rgba(187, 214, 78, 1);
$medium-C: rgba(249, 203, 39, 1);
$high-D: rgba(239, 97, 37, 1);
$very-high-E: rgba(209, 23, 7, 1);

// Carbon labels only
$label-light-turquoise: rgba(227, 248, 229, 1);
