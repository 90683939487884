.collections-private-icon {
  margin: 0 0 2px 6px;
  color: black !important;
  opacity: 30%;
  cursor: pointer;
}

.collections-private-icon:hover {
  color: var(--foodsteps-turquoise) !important;
  opacity: 100% !important;
}
