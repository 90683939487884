.AssessmentOverviewCard,
.AssessmentOverviewChartCard,
.ImpactByFoodTypeCard {
  border: var(--interface-grey) 1px solid;
  box-shadow: none !important;
  height: 100%;
}

.ImpactByFoodTypeCard {
  max-height: 500px;
}

.AssessmentOverviewCard {
  padding: 24px 40px 8px 40px !important;
}

.AssessmentOverviewChartCard {
  display: grid;
  gap: 16px;
  grid-template-rows: min-content 1fr;
  padding: 16px 24px !important;
}

.AssessmentOverview_Panel {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.AssessmentOverviewHeader {
  display: grid;
  gap: 8px;
}

.AssessmentOverviewHeaderRow {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
}

.AssessmentOverviewHeaderVersionInfo {
  display: grid;
  gap: 16px;
  font-weight: var(--font-weight-medium);
  grid-template-columns: repeat(3, auto);
}

.AssessmentOverviewHeaderVersionSelector {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, auto);
  align-items: center;
}

.AssessmentOverviewHeaderNumberOfItems {
  display: grid;
  gap: 6px;
  grid-template-columns: repeat(2, auto);
  align-items: center;
}

.ProcurementDashboardContent .Divider {
  width: 1px;
  background-color: var(--interface-grey);
  margin: 0;
  border: none;
  height: 100%;
}

.ViewAssessmentButtonContent {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 8px;
  align-items: center;
}

.AssessmentOverviewContent {
  display: grid;
  gap: 24px;
}

.AssessmentOverviewCharts {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.GridItem__ProcuredItemsByRating {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}
.GridItem__HighestImpactProcuredItems {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}
.GridItem__ImpactByFoodType {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
}

.AssessmentOverviewHeader_AssessmentSelect {
  width: 206px;
  font-weight: var(--font-weight-normal);
}
