.SummaryCard {
  background-color: var(--bone);
  border: 1px var(--input-border) solid;
  border-radius: 8px;
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}
