.UploadedRecipesTable .yellow-warning-box {
  background: var(--accent-yellow);
  border-radius: 4px;
  font-weight: var(--font-weight-semi-bold);
  padding: 4px;
}

.UploadedRecipesTable .table {
  margin-bottom: 0;
}

.UploadedRecipesTable .table hr {
  margin: 0;
}

.UploadedRecipesTable .cell {
  color: var(--info);
  height: inherit;
  line-height: 4rem;
  overflow: hidden;
}

.UploadedRecipesTable .header {
  justify-items: start;
  color: var(--info);
  line-height: 1rem;
  padding: 2rem 1rem 1rem 1rem;
}

.UploadedRecipesTable .table-row {
  height: 4rem;
  padding: 0 1rem 0 1rem;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.15s ease-out;
}

.UploadedRecipesTable .row__highlighted {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.075);
  transition: background-color 0.25s ease-in;
}

.UploadedRecipesTable .column__name {
  width: 60%;
  overflow: hidden;
}

.UploadedRecipesTable .column__name .cell {
  color: var(--dark-turquoise);
  transition: color 0.15s ease-out;
}

.UploadedRecipesTable .column__name .cell__highlighted {
  color: var(--foodsteps-turquoise);
  transition: color 0.25s ease-in;
}

.UploadedRecipesTable .column__name .cell__edit {
  opacity: 0;
  transition: opacity 0.15s ease-out;
}

.UploadedRecipesTable .column__name .cell__edit__highlighted {
  opacity: 1;
  transition: opacity 0.25s ease-in;
}

.UploadedRecipesTable .column__impact {
  width: 20%;
  text-align: right;
}

.UploadedRecipesTable .header__overflow__left {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  overflow: visible;
}

.UploadedRecipesTable .column__weight {
  width: 20%;
  overflow: hidden;
  text-align: right;
  padding-left: 0.5rem;
}
