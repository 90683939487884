.LifeCycleImpactTable_LifecycleStage {
  padding-left: 0 !important;
}

.LifeCycleImpactTable_LifecycleStage_Square {
  margin: auto 16px auto 0 !important;
}

.LifeCycleImpactTable_LifecycleStage_Percentage {
  width: 48px !important;
}

.LifeCycleImpactTable_Impact {
  padding-right: 0 !important;
}

.LifeCycleImpactTable_OpenStageButton {
  display: flex;
  flex-direction: row;
}

.LifeCycleImpactTable_OpenStageButton_ChevronRight {
  vertical-align: baseline;
  margin: auto 0 auto 4px;
}

.LifeCycleImpactTable_OpenStageButton_ChevronLeft {
  vertical-align: baseline;
  margin: auto 4px auto 0;
}

.LifeCycleImpactTable_SubTable {
  width: calc(100% - 20px - 16px - 40px) !important;
  margin-left: calc(20px + 16px + 40px);
}

.LifeCycleImpactTable_SubTable_OfWhich {
  padding-left: 0 !important;
}
