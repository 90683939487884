.GridListToggle__label {
  padding: 0 !important;
  margin: 0 0.375rem 0 0 !important;
}

.GridListToggle > span:last-of-type > label {
  margin-right: 0 !important;
}

.GridListToggle__label:hover {
  color: var(--foodsteps-turquoise);
}

.GridListToggle__label__active {
  color: var(--dark-turquoise);
}

.GridListToggle__label__inactive {
  color: var(--interface-grey);
}
