.foodsteps-labels-sales-prompt-container {
  background-color: rgba(51, 226, 198, 0.18);
  border-radius: 10px;
  padding: 16px;
  position: relative;
  max-width: 330px;
  height: 100%;
}

.foodsteps-labels-sales-prompt-container .reason-with-inline-label {
  display: flex;
  gap: 8px;
}
