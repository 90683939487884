.input-group-text {
  background-color: var(--bone);
}

.form-control {
  box-shadow: none !important;
  color: black !important;
  border: 1px $input-border solid;
  border-radius: 8px;
}

.form-control:hover:not(:active):not(:focus):not(:disabled) {
  border: 1px $mid-grey solid !important;
}

.form-control:active:not(:disabled),
.form-control:focus {
  border: 1px $foodsteps-turquoise solid;
  box-shadow: 0 2px 10px 0 $light-turquoise !important;
}
