.ImpactComparison__PanelContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ImpactComparison__ButterflyChartContainer {
  margin: 24px 0 0 0;
}

.ImpactComparison_NoImpactMessage_Text {
  padding-left: 6px;
}

.ImpactComparison_Header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
