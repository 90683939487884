.ActionBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--dark-turquoise);
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 var(--drop-shadow-lighter);
}

.ActionBar_NumberOfSelectedProducts {
  color: white;
  display: flex;
  width: 440px;
  height: 44px;
  padding: 0 16px;
  align-items: center;
  flex: 1 0 0;
  flex-direction: row;
  gap: 24px;
}

.ActionBar_SelectAllIncludingUnseen {
  cursor: pointer;
}

.ActionBar_Divider {
  width: 1px;
  align-self: stretch;
  opacity: 0.3;
  background: white;
}

.ActionBar_ButtonContainer {
  cursor: pointer;
  color: white;
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  font-weight: var(--font-weight-medium);
}

.ActionBar_EndButton {
  border-radius: 0 8px 8px 0;
}

.ActionBar_ButtonContainer:hover {
  background: var(--foodsteps-turquoise);
}

.ActionBar .dropdown-menu {
  border-radius: 6px;
  margin: 0 0 2px -2px !important;
  padding: 0;
  border: none;
  box-shadow: 0 2px 10px 0 var(--drop-shadow-darker) !important;
}

.ActionBar .dropdown-divider {
  margin: 0;
}
