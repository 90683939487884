@import "./color-library.scss";

$light: #fbfbfb;
$info: black;
$link-color: $dark-turquoise;
$link-hover-color: #{$foodsteps-turquoise};

$input-focus-border-color: rgba($foodsteps-turquoise, 0.5);

$text-muted: rgba(174, 181, 188, 1);

$checkbox-border: #aeb5bc;

.bg-light {
  background-image: $bone-to-white !important;
}

// Overwrite bootstrap colors to style buttons
$primary: black;
$secondary: white;
$dark: $dark-turquoise;

// Used in the calculation table
$light-accent-yellow: rgba(240, 229, 43, 0.3);
$light-marine: rgba(41, 89, 204, 0.3);
$light-marooned: rgba(177, 28, 86, 0.3);
$pastel-pink: rgba(255, 192, 203, 0.3);
