.Processing__Panel {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 40px !important;
}

.Processing_AddIngredientChevron {
  vertical-align: baseline;
}

.Processing__Panel__Section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Processing__Panel__Section__Border {
  padding-bottom: 32px;
  border-bottom: 1px var(--interface-grey) solid;
}

.Processing__IngredientQuantityInstructions {
  margin-bottom: 32px;
}

.EnergyUseEditor {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Processing_AddIngredientButton {
  margin-top: 16px;
}
