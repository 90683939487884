.LifeCycleImpacts_SunburstAndTableContainer {
  display: flex;
  flex-direction: row;
  gap: 75px;
  min-height: 485px;
}

.LifeCycleImpacts_Transitioning {
  cursor: none !important;
}

.LifeCycleImpacts_Panel {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.LifeCycleImpacts_Panel > h3 {
  margin-bottom: 0;
}

.LifeCycleImpacts_Header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.LifeCycleImpacts_ImpactCategoryInlineTitleToggle {
  margin-left: 6px;
}
