.ReportPage_Header {
  top: -84px;
}

.ReportPage_ContentContainer {
  padding: 24px;
}

.ReportPage_Header_Controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
}

.ReportPage_AssessmentSelect {
  width: 206px;
}
