.SignUpPage_Background {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.SignUpPage_Card {
  width: 600px;
  height: fit-content;
  margin: 32px auto;
  padding: 32px !important;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.SignUpCard_Header {
  width: 100%;
  display: inline-block;
  text-align: center;
}

.SignUpPage_Footer {
  width: 100%;
  display: inline-block;
  text-align: center;
}
