.ActionModal {
  display: flex !important;
}

.ActionModal .modal-dialog {
  align-self: center;
  display: flex;
}

.ActionModal .modal-lg {
  width: 880px !important;
}

.ActionModal .modal-body {
  padding: 0 40px 32px 40px;
}

.ActionModal .modal-header {
  padding: 32px 40px 16px 40px;
}

.ActionModal .modal-footer {
  padding: 0 40px 32px 40px;
}
