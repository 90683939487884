.ReportContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ReportContent_TableHeader {
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: row;
  padding-bottom: 16px;
}

.ReportContent_SummaryCard {
  width: 25%;
  height: 124px;
}

.ReportContent_Panel {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ReportContent_SummaryCards {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.ReportContent_TableTypeSelect_Container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin-bottom: 24px;
}

.ReportContent_BreakdownBy {
  margin: 0;
}
