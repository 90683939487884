.UpdateIngredientsBanner_container {
  background-color: var(--interface-light-turquoise);
  border-radius: 0.5rem;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.UpdateIngredientsBanner_attention-triangle {
  position: absolute;
  right: 25%;
  top: 25%;
}
