.Sunburst_Container {
  position: relative;
  background-color: white;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Sunburst_CenterContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 150px;
  text-align: center;
}

.Sunburst_CenterContent {
  opacity: 0;
  animation-name: Sunburst_CenterContainer_fadeIn;
  animation-duration: 500ms; /* Keep this the same as TRANSITION_DURATION_MILLISECONDS */
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-delay: 0ms;
  animation-fill-mode: forwards;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

@keyframes Sunburst_CenterContainer_fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
