.BackButton {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  padding: 0 !important;
  box-shadow: 0 2px 10px 0 var(--drop-shadow-darker);
}

.BackButton__Icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
