.recipe-owner-lozenge {
  background: #f4f4f0;
  border-radius: 6px;
  display: flex;
  padding: 6px 8px;
  align-items: flex-start;
  gap: 8px;
  color: black;
}

.RecipePageContent_RecipePageHeader {
  position: sticky;
  top: -100px;
  z-index: 999;
  background-color: white;
  border-bottom: 1px solid var(--interface-grey);
  display: flex;
  padding: 16px 40px 16px 24px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.RecipePageContent_RecipePageHeader_Controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  margin-top: 8px;
}

.RecipePageContent_RecipePageHeader_PageTitle {
  max-width: 720px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
