.card {
  background-color: var(--white);
  /* this border can't be seen
  but is needed so the card doesn't change height when selected */
  border: 1px solid var(--white);
  transition: 300ms;
  border-radius: 8px !important;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
}

.card_no_shadow_border {
  border: 1px solid var(--interface-grey);
  box-shadow: none;
}

.card__hover:hover {
  border-color: var(--foodsteps-turquoise);
  cursor: pointer;
}

.card__selected {
  background-color: var(--interface-light-turquoise);
  border-color: var(--foodsteps-turquoise);
}
