.ItemImpactsDisplay {
  display: grid;
  gap: 16px;
}

.ItemImpactCard {
  display: grid;
  grid-template-columns: repeat(2, auto);
  background-color: var(--bone);
  border-radius: 8px;
  gap: 8px;
  padding: 12px 16px;
  justify-content: space-between;
  height: 100%;
}

.NameAndRank {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 8px;
  align-items: center;
}

.NameAndRank .Rank {
  font-size: 16px;
}

.NameAndRank .Name {
  font-size: 13px !important;
}

.NumberAndRatingLabel {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 8px;
}

.ImpactCardTotalImpact {
  max-width: 80px;
  align-self: center;
}

.ImpactCardTotalImpactUnit {
  font-size: 12px;
  line-height: 12px;
}

.ImpactRatingDisplayContainer {
  width: 100%;
  min-width: 72px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ViewMoreButtonContainer {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 6px;
  align-items: center;
  justify-content: flex-start;
}
