.searchbox {
  flex-basis: 350px;
  flex-grow: 0;
  flex-shrink: 0;
}

.searchbox form {
  width: 100%;
  margin: 0;
  color: black !important;
}

.searchbox form .input-field {
  position: relative;
}

.searchbox form .input-field input {
  border: 1px solid;
  border-radius: 8px;
  display: block;
  width: 100%;
  padding: 1rem 12px 1rem 40px;
  font-size: 16px;
  height: 2.5em;
  border-color: var(--input-border);
}

.searchbox form .input-field input:hover,
.searchbox form .input-field input:focus {
  outline: 0;
}

.searchbox form .input-field input:focus {
  box-shadow: 0 2px 10px 0 var(--interface-light-turquoise) !important;
}

.searchbox form .input-field input:hover:not(:disabled):not(:focus) {
  border: 1px var(--mid-grey) solid !important;
}

.searchbox form .input-field input:focus {
  border: 1px var(--foodsteps-turquoise) solid !important;
}

.searchbox form .input-field .icon-wrap {
  position: absolute;
  top: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: center;
  align-items: center;
  width: 25px;
  height: 100%;
  color: var(--input-border);
}
