.action-link {
  color: $link-color;
  cursor: pointer;
  font-weight: var(--font-weight-medium);
}

.action-link-hover,
.action-link:hover {
  color: $link-hover-color;
}

.action-link-info {
  color: $info;
}

.text-inactive {
  opacity: 0.3;
}

.text-primary {
  color: var(--foodsteps-turquoise) !important;
}
