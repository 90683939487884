.TagContainer {
  height: 32px;
  display: flex;
  flex-direction: row;
}

.TagTextContainer {
  background-color: var(--bone);
  padding: 6px 2px 6px 6px;
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 4px 0 0 4px;
}

.TagArrow {
  width: 0;
  height: 0;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 9px solid var(--bone);
}
