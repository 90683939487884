.RowActions {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: flex-end;
  padding: 2px 0 0 0;
}

.RowActions_Bin {
  color: var(--interface-grey);
}

.RowActions_Bin,
.RowActions_Bin__EditingRow {
  position: relative;
  top: 2.5px;
}

.RowActions_Bin__RowHighlighted,
.RowActions_Bin__EditingRow,
.RowActions__Edit {
  color: var(--dark-turquoise);
}

.RowActions_Bin:hover,
.RowActions_Bin__RowHighlighted:hover,
.RowActions_Bin__EditingRow:hover,
.RowActions__Edit:hover,
.RowActions__EditIcon:hover {
  color: var(--foodsteps-turquoise);
  cursor: pointer;
}

.RowActions__Edit {
  display: flex;
  gap: 6px;
}

.RowActions__EditText {
  padding-top: 1px;
}
