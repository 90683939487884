.foodsteps-dropdown .dropdown-item {
  margin: 0;
  line-height: 1.2;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--info);
}

.foodsteps-dropdown .dropdown-item,
.foodsteps-dropdown .dropdown-header {
  font-size: 80%;
}

.foodsteps-dropdown .dropdown-divider {
  margin: 0 !important;
}

.foodsteps-dropdown .dropdown-toggle::after {
  display: none;
}

.foodsteps-dropdown .dropdown-item:active {
  color: var(--white);
}

.Dropdown_ToggleContent {
  display: flex;
  align-items: center;
  gap: 8px;
}
