.PageTitle_InlineAndFinalTitleContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 16px 0;
}

.PageTitle_InlineAndFinalTitleContainer__RightAlignedEnd {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.StickyPageTitle {
  position: sticky;
  z-index: 100;
  background-color: white;
  border-bottom: 1px solid var(--interface-grey);
  display: flex;
  padding: 16px 24px 16px 24px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
