.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: var(--foodsteps-turquoise);
  border: var(--interface-grey) solid 1px;
  color: white;
  text-align: center;
  content: url("./dash.svg");
  line-height: 70%; /* This centers the dash in the checkbox */
}
