.copy-to-button {
  /* this is needed to force the tooltip to not overlap with the button */
  display: flex;
}

.copy-to-button-modal-title {
  font-weight: var(--font-weight-normal);
  font-size: 20px;
  line-height: 29px;
  padding-top: 8px;
}

.copy-to-button-modal .modal-body {
  padding-bottom: 0px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.copy-to-button-modal .modal-footer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-top: 0px;
}

.copy-to-button-modal .modal-footer .footer-buttons {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  gap: 16px;
}

.copy-to-button-modal .multi-select-collections {
  margin-bottom: 63px;
}

.CopyToButton_ToastContainer {
  z-index: 1;
}

.CopyToButton_TagModal_CancelButton {
  margin-right: 16px;
}

.CopyToButton_TagModal_Instructions {
  font-weight: var(--font-weight-medium);
  margin-bottom: 16px;
}
