.packaging-switch-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.packaging-switch-container label {
  padding-left: 0.5rem;
  padding-right: 1.1rem;
  /* fixed width stops the switch from changing positions
  when we change the label text */
  width: 11rem;
}

.packaging-switch-container > span {
  display: inline-flex;
}

.packaging-switch-container .when-available-label-text {
  width: 20rem;
}

.packaging-switch {
  color: var(--dark-turquoise);
}

.packaging-switch-enabled:hover .packaging-icon {
  color: var(--foodsteps-turquoise);
}

.packaging-switch-disabled {
  opacity: 0.3 !important;
}

.packaging-switch-only-container {
  height: 40px;
}
