:root {
  --checkbox-size: 1.375rem;
}

.custom-checkbox {
  padding-left: calc(
    var(--checkbox-size) + var(--checkbox-radio-button-label-space)
  );
}

input[type="checkbox"] ~ .custom-control-label::before {
  border-color: var(--interface-grey);
  height: var(--checkbox-size);
  width: var(--checkbox-size);
  left: calc(
    -1 * (var(--checkbox-size) + var(--checkbox-radio-button-label-space))
  );
  /* bootstrap sets top: 0.25rem, to vertically center a 1rem checkbox in a container of height 1.5rem
  so to stay vertically centered we adjust top by half our extra checkbox height */
  top: calc(0.25rem - 0.5 * (var(--checkbox-size) - 1rem));
  transition: var(--checkbox-radio-button-transition);
}

input[type="checkbox"] ~ .custom-control-label::after {
  border-color: var(--interface-grey);
  transition: var(--checkbox-radio-button-transition);
  /* match the changes to .custom-control-label::before */
  height: var(--checkbox-size);
  width: var(--checkbox-size);
  left: calc(
    -1 * (var(--checkbox-size) + var(--checkbox-radio-button-label-space))
  );
  top: calc(0.25rem - 0.5 * (var(--checkbox-size) - 1rem));
}

/* to make the 'check' svg fade in and out with the expected transition time, we set it to always be always be the background image (even when unchecked)
and use opacity to control its visibility */
input[type="checkbox"] ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg%20width=%2210%22%20height=%228%22%20viewBox=%220%200%2010%208%22%20fill=%22none%22%20xmlns=%22http://www.w3.org/2000/svg%22%3E%3Cpath%20fillRule=%22evenodd%22%20clipRule=%22evenodd%22%20d=%22M9.71523%200.231404C10.0684%200.564438%2010.097%201.13439%209.77917%201.50443L4.45659%207.7016C4.29872%207.88541%204.07572%207.99319%203.83982%207.99969C3.60391%208.00619%203.37581%207.91085%203.20894%207.73598L0.251951%204.6374C-0.0839837%204.28537%20-0.0839837%203.71463%200.251951%203.36261C0.587886%203.01059%201.13254%203.01059%201.46848%203.36261L3.78435%205.78938L8.50039%200.298405C8.8182%20-0.0716314%209.3621%20-0.101629%209.71523%200.231404Z%22%20fill=%22white%22/%3E%3C/svg%3E%0A") !important;
  transition: var(--checkbox-radio-button-transition);
  opacity: 100%;
}

input[type="checkbox"]:not(:checked) ~ .custom-control-label::after {
  opacity: 0%;
}

input[type="checkbox"]:focus ~ .custom-control-label::before {
  border-color: var(--foodsteps-turquoise) !important;
  box-shadow: var(--checkbox-radio-button-box-shadow);
}

input[type="checkbox"]:active:not(:checked):not(:disabled)
  ~ .custom-control-label::before {
  border-color: var(--foodsteps-turquoise);
  background-color: white;
}

input[type="checkbox"]:active:checked ~ .custom-control-label::before {
  border-color: var(--foodsteps-turquoise);
  background-color: var(--foodsteps-turquoise);
}

input[type="checkbox"]:checked ~ .custom-control-label::before {
  border-color: var(--foodsteps-turquoise);
  background-color: var(--foodsteps-turquoise);
}

.custom-checkbox-hover
  > input[type="checkbox"]:not(:focus):not(:disabled)
  ~ .custom-control-label::before,
input[type="checkbox"]:hover:not(:focus):not(:disabled)
  ~ .custom-control-label::before {
  border-color: var(--foodsteps-turquoise) !important;
  box-shadow: var(--checkbox-radio-button-box-shadow);
}

input[type="checkbox"]:disabled ~ .custom-control-label::before {
  border-color: var(--interface-grey) !important;
  box-shadow: none;
}

:not(.custom-checkbox-hover)
  > input[type="checkbox"]:focus
  ~ .custom-control-label::before {
  border-color: var(--mid-grey) !important;
}
