.ImpactSummary_Panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.ImpactSummary__Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ImpactSummary__ImpactCategoryCardsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.ImpactSummary__ImpactCategoryCardContainer {
  width: 20% !important;
}

.ImpactSummary_DQSTooltipLabelContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
