.TableEditor_RowContainer:not(:last-child) {
  padding-bottom: 8px;
}

.TableEditor_Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
