.FunctionalUnitDropdown__Toggle {
  display: flex;
  box-shadow: none !important;
  padding: 0 !important;
  border: none;
}

.FunctionalUnitDropdown__Item {
  padding: 8px 12px !important;
}

.FunctionalUnitDropdown__Item__First {
  border-radius: 7px 7px 0 0;
}

.FunctionalUnitDropdown__Item__Last {
  border-radius: 0 0 7px 7px;
}

.FunctionalUnitDropdown__Item:hover {
  background-color: var(--bone);
}

.FunctionalUnitDropdown__Item__Active {
  color: white !important;
  background-color: var(--foodsteps-turquoise) !important;
}

.FunctionalUnitDropdown .dropdown-toggle::after {
  display: none;
}

.FunctionalUnitDropdown__Menu {
  width: fit-content;
  min-width: 160px;
  padding: 0;
  border: 1px var(--interface-grey) solid !important;
  border-radius: 8px;
  margin-top: 4px;
}

.FunctionalUnitDropdown__Chevron {
  color: var(--input-border);
}

.FunctionalUnitDropdown__Chevron__Hover {
  color: var(--mid-grey);
}

.FunctionalUnitDropdown__Chevron__Expanded {
  color: var(--dark-grey);
}
