.Accordion {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.Accordion_HeaderCard {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background-color: white;
  color: var(--dark-turquoise);
  cursor: pointer;
}

.Accordion_HeaderCard:hover,
.Accordion_HeaderCard_Open {
  background-color: var(--bone);
}

.Accordion_HeaderCard:hover {
  color: var(--foodsteps-turquoise);
}

.Accordion_HeaderCard_Active,
.Accordion_HeaderCard_Active:hover {
  background-color: var(--interface-light-turquoise);
}

.Accordion_HeaderCard_Right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.Accordion_HeaderCard_FilledCircle {
  font-size: 13px;
  font-weight: var(--font-weight-medium);
}

.Accordion_Body {
  background-color: white;
  border-radius: 8px;
  margin-top: 4px;
}

.Accordion_Option {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
}

.Accordion_Option:hover {
  background-color: var(--bone);
}

.Accordion_Option:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.Accordion_Option:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.Accordion_Option_Checked,
.Accordion_Option_Checked:hover {
  background-color: var(--interface-light-turquoise);
}
