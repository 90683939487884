.ProductsDashboardPage_Header {
  top: 0;
}

.ProductsDashboardPageContent {
  display: grid;
  gap: 24px;
  padding: 24px;
}

.ProductsDashboardPage_Panel {
  padding: 16px 24px !important;
}

.ProductsDashboardCharts {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.ProductsByRating_And_HighestAndLowestImpactProducts_Container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ProductsByRatingChart {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.HighestAndLowestImpactProducts {
  display: flex;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 24px;
  align-self: stretch;
}

.AverageImpactIntensityByTag {
  height: 327px;
}

.ProductsDashboardPageControls {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.ProductsDashboardPage_OrganizationSelect {
  width: 300px;
}
