.ProductFilterSelect {
  margin-right: -16px;
}

.ProductFilterButton {
  display: flex;
  gap: 4px;
  align-items: center;
}

.ProductFilterButton__Filtered {
  padding-right: 0px !important;
}

.ProductFilterButton .number-of-filters {
  font-size: 13px;
}

.ProductFilterButton .filter-icon {
  margin-right: 4px;
}

.ProductFilterButton .CloseButton {
  /* TODO: if we get rid of borders around buttons then we can remove 'left' and 'position' properties */
  position: relative;
  left: 1px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  align-content: center;
  padding: 12px 6px 12px 6px;
  display: flex;
  align-items: center;
  background-color: var(--dark-turquoise);
}

.ProductFilterButton .CloseButton {
  color: var(--white);
  border: none;
}

.ProductFilterButton .CloseButton:hover {
  background-color: var(--foodsteps-turquoise);
}

/* this first selector tells ProductFilterButton to NOT change if the bit of it being hovered is behind the CloseButton */
.ProductFilterButton:has(.CloseButton:hover):hover,
.ProductFilterButton__MenuOpen,
.ProductFilterButton__Filtered {
  background-color: var(--dark-turquoise) !important;
  color: white !important;
  border-color: var(--dark-turquoise) !important;
}

.filter-wrapper {
  position: relative;
}

.ProductFilterMenuList {
  max-height: 480px;
}

.ProductFilterMenuList .ManageTagsButton:hover {
  background-color: white !important;
  border-color: white !important;
  color: var(--foodsteps-turquoise) !important;
}
/* ------------ */

.ProductFilterMenuList {
  background-color: white;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 0 4px 16px 16px;
  position: absolute;
  top: 100%;
  width: 440px;
  max-height: min(70vh, 800px);
}

.ProductFilterMenuList .Header {
  align-items: center;
  padding: 16px 12px 12px 12px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
}

.ProductFilterMenuList .Title {
  margin: 0;
}

.ProductFilterMenuList .CloseButton {
  color: var(--dark-turquoise);
  background: none;
  border: none;
  padding: 0;
  margin-right: -2px;
  font-size: 20px;
  cursor: pointer;
}

.ProductFilterMenuList .FilterSection {
  overflow: scroll;
  /*As scrollbar adds to the height of the container, we need to remove it from the width of the container*/
  margin-bottom: -12px;
}

.ProductFilterMenuList .Divider {
  color: grey;
  height: 1px;
  margin: 4px 24px 4px 24px;
}

.ProductFilterMenuList .CloseButton:hover {
  color: var(--foodsteps-turquoise);
}

.ProductFilterMenuList .custom-control-label {
  cursor: pointer;
}

.ProductFilterMenuList .GeneralStatusSection {
  display: flex;
  flex-direction: column;
}

.ProductFilterMenuList .GeneralStatusSection > div {
  padding: 8px 24px 8px 24px;
}

.ProductFilterMenuList .GeneralStatusSection .ListItem {
  cursor: pointer;
}

.ProductFilterMenuList .GeneralStatusSection .ListItem:hover {
  background-color: var(--bone);
}

.ProductFilterMenuList .CollectionsTableHeader {
  align-items: center;
  padding: 12px 24px 8px 24px;
  justify-content: space-between;
}

.ProductFilterMenuList .any-or-all-row {
  padding: 0px 12px 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.ProductFilterMenuList .SearchBox {
  padding: 0 12px 8px 12px;
}

.ProductFilterMenuList .CollectionsTableContainer {
  display: flex;
  overflow: auto;
  max-height: min(28vh, 350px) !important;
  border-radius: 8px;
  border: 1px solid var(--input-border);
  margin: 0 12px 0 12px;
}

.ProductFilterMenuList .CollectionsTable {
  display: table;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.CollectionsTable .ListItem,
.CollectionsTable .ListItem__RowHighlighted {
  display: flex;
  padding: 8px 0 8px 0;
  cursor: pointer;
}

.CollectionsTable .ListItem__RowHighlighted {
  background-color: var(--bone);
}

.CollectionsTable .Checkbox {
  margin-left: 12px !important;
}

.ProductFilterMenuList .EndButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 8px 24px 0 0;
}

.ProductFilterMenuList ::-webkit-scrollbar-track {
  margin-top: 0;
}

.ProductFilterMenuList .react-select__single-value {
  max-width: unset;
}

.ProductFilterMenuList .react-select__indicators {
  width: 26px;
}

.ProductFilterMenuList .react-select__indicators svg {
  fill: var(--input-border);
}

.ProductFilterMenuList .react-select__indicators svg:hover {
  fill: var(--hover-toggle-button-color);
}

.ProductFilterMenuList
  .react-select__control--is-focused
  .react-select__indicators
  svg,
.ProductFilterMenuList
  .react-select__control--is-focused
  .react-select__indicators
  svg:hover {
  fill: var(--dark-grey);
}

.ProductFilterMenuList .react-select__single-value {
  font-size: 16px;
}

.ProductFilterMenuList .react-select__option:first-child {
  border-radius: 8px 8px 0 0;
}

.ProductFilterMenuList .react-select__option:last-child {
  border-radius: 0 0 8px 8px;
}

.ProductFilterSelect_SelectContainer {
  width: 80px;
}
