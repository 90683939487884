.PhysicalInputsEditor {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.PhysicalInputsEditor_Table {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
