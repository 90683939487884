.ButterflyChartRow_Container {
  display: flex;
  flex-direction: row;
}

.ButterflyChartRow_Left {
  width: calc(50% - 140px / 2);
}

.ButterflyChartRow_Center {
  width: 140px;
}

.ButterflyChartRow_Right {
  width: calc(50% - 140px / 2);
}

.ButterflyChart_LeftContentContainer {
  height: 100%;
  width: 100%;
  position: relative;
}

.ButterflyChart_BarContainer,
.ButterflyChart_PercentageDifferenceContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ButterflyChart_HeaderCellContainer {
  height: 57px;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.ButterflyChart_HeaderCellBorderBottom {
  border-bottom: 1px solid var(--interface-grey);
}

.ButterflyChart_RowContainer {
  height: 44px;
  margin-bottom: 16px;
}
