.RecipesPanel_CalculatingImpactsSpinner {
  width: 20px;
  height: 20px;
}

.RecipesPanel_ActionBar {
  position: fixed;
  bottom: 24px;
  left: 55%;
  transform: translateX(-50%);
  z-index: 10;
}

.RecipesPanel {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}
