.ProductEditorHeader__Navbar {
  background-color: white;
  border-bottom: 1px solid var(--interface-grey);
  padding: 12px 40px;
  max-height: 65px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
}

.ProductEditorHeader__CancelOrSaveWrapper {
  max-height: 0;
  overflow: visible;
  width: 230px;
  display: flex;
  align-items: flex-start;
  margin-top: -40px;
  direction: rtl;
}

.ProductEditorHeader__Header {
  display: flex;
  align-items: flex-end;
  direction: ltr;
}

.ProductEditorHeader__BelowErrorAlert {
  width: 405px;
  direction: ltr;
}

.LeftErrorAlert {
  width: 405px;
}

.ProductEditorHeader__ControlsContainer {
  padding-bottom: 48px;
  width: 880px;
  margin: 0 auto;
}

.ProductEditorHeader__StepContentContainer {
  padding-top: 32px;
  padding-bottom: 24px;
  width: 880px;
  margin: 0 auto;
}

.ProductWeightEditor_TitleAndInstructionsContainer {
  max-width: 620px;
}
