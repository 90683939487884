.StatusDisplay_loading {
  opacity: 0;
  animation-name: StatusDisplay_loading_fadeIn;
  animation-duration: 600ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-delay: 400ms;
  animation-fill-mode: forwards;
  margin: 1rem;
}

@keyframes StatusDisplay_loading_fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
