.breadcrumb {
  background: none;
  font-size: 80%;
  margin-bottom: 0;
  padding: 0;
  color: var(--mid-grey) !important;
}

.breadcrumb-item,
.breadcrumb-item::before {
  color: var(--mid-grey) !important;
}
