.Navbar__Sidebar {
  border-color: var(--interface-grey) !important;
  /*44px is the height of the footer*/
  height: calc(100% - 44px);
  width: 240px !important;
}

.ps-sidebar-container {
  background-color: white !important;
}

.ps-menu-root {
  padding: 16px 16px 0 12px !important;
}

.ps-menu-button {
  display: flex;
  background-color: white !important;
  color: var(--dark-turquoise) !important;
  padding: 12px !important;
  height: 48px !important;
  gap: 8px;
  border-radius: 8px !important;
}

.ps-submenu-content .ps-menu-button {
  height: 40px !important;
}

.ps-submenu-content .SubMenu__Item .ps-menu-button {
  height: auto !important;
  min-height: 40px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ps-menu-button:hover {
  background-color: var(--bone) !important;
  color: var(--foodsteps-turquoise) !important;
}

.Navbar__MenuItem__active .ps-menu-button {
  background-color: var(--dark-turquoise) !important;
  color: white !important;
  border-radius: 8px;
}

.Navbar__MenuItem__locked .ps-menu-button {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.3) !important;
  padding-right: 0 !important;
  border-radius: 8px;
  cursor: default !important;
}

.Navbar__MenuItem__locked .ps-menuitem-root {
  padding: 16px 12px 16px 12px;
}

.Navbar__MenuItem__locked .ps-menu-label {
  display: flex;
  width: 100%;
}

.Navbar__MenuItem__locked .private-icon {
  background-color: transparent !important;
  cursor: pointer !important;
  margin-right: 10px;
  position: relative;
  bottom: 2px;
  right: 0;
}

.tooltip-right {
  background-color: transparent !important;
}

.tooltip-right .tooltip-inner {
  background-color: rgba(0, 0, 0, 0.8) !important;
  margin-left: -2px !important;
}

.tooltip-right .arrow {
  color: rgba(0, 0, 0, 0.8) !important;
  margin-right: -2px;
}

.Navbar__MenuItem__locked .private-icon:hover {
  color: var(--foodsteps-turquoise) !important;
}

.Navbar__SubMenu .ps-menuitem-root {
  background-color: white !important;
}

.ps-submenu-content .ps-menu-button {
  padding: 16px 0 16px 0 !important;
  margin-left: 16px !important;
}

.ps-submenu-root .Navbar__SubMenu .ps-menu-button {
  margin-left: 0 !important;
}

.ps-submenu-content .ps-submenu-root .ps-menu-button {
  padding-left: 0 !important;
  padding-right: 12px !important;
}

.SubMenu__Item .Navbar__SubMenu .ps-submenu-content {
  margin-left: 16px !important;
  margin-bottom: 0 !important;
}

.li {
  margin-bottom: 0 !important;
}

.Navbar__SubMenu__closed_and_active .ps-menu-button {
  background-color: var(--dark-turquoise) !important;
  color: white !important;
  border-radius: 8px !important;
}

.ps-submenu-root .ps-open {
  background-color: var(--bone) !important;
  border-radius: 8px;
  margin-bottom: 4px !important;
}

.ps-submenu-root .ps-open > .ps-menu-label,
.ps-submenu-root .ps-open > .ps-menu-icon {
  margin-bottom: 0 !important;
}

.ps-submenu-root .ps-open .Navbar__SubMenu {
  background-color: var(--white) !important;
  border-radius: 8px;
  margin-bottom: 0 !important;
}

.ps-menu-button .Navbar__SubMenu {
  height: 100%;
}

.Navbar__SubMenu {
  background-color: white !important;
}

.ps-menuitem-root .ps-submenu-root .ps-open {
  background-color: var(--bone) !important;
  border-radius: 8px;
}

.ps-menu-icon {
  margin: 0 !important;
  min-width: 20px !important;
  width: 20px !important;
}

.ps-submenu-expand-icon {
  display: none;
}

.SubMenu__Label__Title {
  float: left;
}

.ps-submenu-content .SubMenu__Label__Title {
  padding-left: 24px;
}

.SubMenu__Label__Chevron__Container {
  height: 100%;
  float: right;
}

.Navbar_SystemBoundaryDisplay {
  margin-top: 24px;
  margin-bottom: 32px;
}

.Navbar_SectionHeader {
  padding: 0 12px 8px 12px;
}

.Navbar_SidebarAndFooterContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.IndentedNavbarItem {
  /*Text should align with parent element with icon - equal to */
  /*padding-left of parent element + width of icon - indent of submenu item*/
  padding-left: 24px;
}

.SubMenuListWrapper {
  padding-left: 0 !important;
  list-style-type: none;
  width: 100% !important;
}
