.RecipesDropdown {
  border-radius: 8px;
}

.RecipesDropdown__dropdownItem {
  text-align: left;
  width: 100%;
  white-space: nowrap;
  padding: 0.875em !important;
  font-weight: var(--font-weight-medium);
}

.RecipesDropdown__dropdownItem:hover {
  background-color: transparent;
}

.RecipesDropdown__menu {
  margin-top: 0.75rem;
  border-radius: 6px;
}

.RecipesDropdownButton__Icon {
  margin: 0 4px 2px 0;
}
