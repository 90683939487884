.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: stretch;
  border: 1px solid var(--input-border);
  border-radius: 6px;
  background-color: white;
  margin-top: 3px;
  text-align: left;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 4;
}

.react_autosuggest__container {
  position: relative;
  width: 100%;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 5;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0.375rem 0.75rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: var(--bone);
}
