.recipes-table {
  min-height: 0;
}

.recipes-table .attention-triangle {
  /* to stop the svg from shrinking when table is narrow */
  min-width: 1.4em;
  width: 1.4em;
}

.recipes-table .recipe-table-row {
  cursor: pointer;
}

.recipes-table .recipe-name-cell {
  gap: 0.5rem;
  color: var(--dark-turquoise);
  font-weight: var(--font-weight-medium);
  padding-left: 0 !important;
}

.recipes-table .recipe-name-cell__highlighted {
  color: var(--foodsteps-turquoise);
}

.recipes-table .delete-recipe-column {
  padding-right: 1rem;
  width: 0;
}

.recipes-table .collections-column {
  width: 20%;
}

.RecipesTable_Stale {
  opacity: 30%;
}

.RecipesTable_ImpactCell {
  position: relative;
  justify-content: flex-end;
  align-items: center;
  background-color: peachpuff;
}

.RecipesTable_SpinnerContainer {
  position: absolute;
  top: 0;
  right: 4px;
  transform: translate(0, -50%);
}

.RecipesTable_ImpactContainer {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, -50%);
}

.RecipesTable_Spinner {
  width: 24px;
  height: 24px;
}

.RecipesTable_Panel {
  padding: 24px !important;
}
