@import "scss/colors.scss";
@import "scss/typography.scss";

:root {
  --label-A: #{$very-low-A};
  --label-B: #{$low-B};
  --label-C: #{$medium-C};
  --label-D: #{$high-D};
  --label-E: #{$very-high-E};
  --light-blue: #c1e3e9;
  --white: #ffffff;
  --font-weight-normal: #{$font-weight-normal};
  --font-weight-medium: #{$font-weight-medium};
  --font-weight-semi-bold: #{$font-weight-semi-bold};
  --font-weight-bold: #{$font-weight-bold};
  --interface-light-turquoise: #d0e9e5;
  --foodsteps-turquoise: #00a68f;
  --interface-input-glow: #00a68f;
  --accent-yellow: #{$accent-yellow};
  --dark-turquoise: #{$dark-turquoise};
  --drop-shadow-darker: #{$drop-shadow-darker};
  --drop-shadow-lighter: #{$drop-shadow-lighter};
  --interface-grey: #{$interface-grey};
  --input-border: #{$input-border};
  --mid-grey: #{$mid-grey};
  --accent-yellow: #{$accent-yellow};
  --light-accent-yellow: #{$light-accent-yellow};
  --light-marine: #{$light-marine};
  --light-marooned: #{$light-marooned};
  --interface-panel-dropshadow: #{$interface-panel-dropshadow};
  --dark-grey: #{$dark-grey};
  --aqua: #{$aqua};
  --mint-pastel: #{$mint-pastel};
  --pastel-pink: #{$pastel-pink};
  --text-muted: #{$text-muted};
}

html,
body,
#root,
.App {
  height: 100vh;
}

.bg-white {
  background-color: var(--white);
}

.label-a {
  color: var(--label-A);
}
.label-b {
  color: var(--label-B);
}
.label-c {
  color: var(--label-C);
}
.label-d {
  color: var(--label-D);
}
.label-e {
  color: var(--label-E);
}

.label-bg-a {
  background-color: var(--label-A);
}
.label-bg-b {
  background-color: var(--label-B);
}
.label-bg-c {
  background-color: var(--label-C);
}
.label-bg-d {
  background-color: var(--label-D);
}
.label-bg-e {
  background-color: var(--label-E);
}

.light-blue {
  background-color: var(--light-blue);
}

.rounded-lg {
  border-radius: 8px !important;
}

.btn-light:focus:active {
  outline: none !important;
  box-shadow: none !important;
}

.btn-light:focus {
  outline: none !important;
  box-shadow: none !important;
  background: none !important;
}

.btn-light:active {
  background: none !important;
}

.btn-light:hover {
  background-color: #d9f5eb !important;
}

.btn-secondary {
  color: var(--dark-turquoise);
  box-shadow: 0 2px 10px 0 var(--drop-shadow-darker);
}

.normal-font {
  font-weight: var(--font-weight-normal);
}

.medium-font {
  font-weight: var(--font-weight-medium);
}

.semi-bold-font {
  font-weight: var(--font-weight-semi-bold);
}

.bold-font {
  font-weight: var(--font-weight-bold);
}

.back-btn,
.clickable {
  cursor: pointer;
}

.vh-40 {
  height: 40vh;
}
.vh-50 {
  height: 50vh;
}
.vh-100 {
  height: 100vh;
}

td {
  border: none;
}

body {
  margin: 0;
}

.table-tight td:first-child {
  padding-left: 0;
}

.table-tight td:last-child {
  padding-right: 0;
}

.table thead th {
  padding-top: 0;
}

.table thead td {
  padding-top: 1.25rem;
}

.table th,
.table td,
.table__cell--padding {
  padding: 0.75rem 1.25rem;
}

.table-bordered-outer {
  border: 1px solid #dee2e6;
}

.btn-primary,
.btn-secondary,
.btn-danger {
  border-radius: 8px;
}

.btn:not(.dropdown-toggle-split) {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.flex-fit {
  flex: 1 1 0;
}

.flex-column > .flex-fit {
  min-height: 0;
}

strong {
  font-weight: var(--font-weight-semi-bold);
}

a {
  font-weight: var(--font-weight-medium);
}

::-webkit-scrollbar {
  display: block;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 10px 10px var(--interface-grey);
  border: solid 3px transparent;
}
