.DQSInformationModalTooltip_DQSRangeBoxesContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.DQSInformationModalTooltip_DQSRangeBox {
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--interface-grey);
}
