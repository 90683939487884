.NoTagsDisplay {
  display: flex;
  flex-direction: row;
  background-color: var(--interface-light-turquoise);
  border-radius: 8px;
  padding: 16px 12px 16px 12px;
  gap: 16px;
  align-items: center;
  margin-top: 8px;
}

.NoTagsDisplay__Text {
  display: flex;
  line-height: 20px;
  width: 248px;
}

.NoTagsDisplay .ManageTagsButton__WithText {
  font-size: 13px;
  height: 32px;
  border: none;
}
