.custom-tooltip {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 6px;
  padding: 8px;
  color: white;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 0);
  transition: all 0.1s ease;
  max-width: 260px;
  min-width: 175px;
}

.custom-tooltip:before {
  content: "";
  position: absolute;
  bottom: -6px;
  left: calc(50% - 5px);
  border-width: 6px 5px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent;
  width: 0;
}
