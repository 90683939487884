.IconButton__disabled {
  pointer-events: none;
  opacity: 0.65;
}

.LightButton {
  background-color: transparent !important;
  border: none;
  color: var(--dark-turquoise);
}

.LightButton:hover {
  background-color: transparent !important;
  color: var(--foodsteps-turquoise);
}

.LightButton:focus,
.LightButton:not(:disabled):active {
  color: var(--foodsteps-turquoise) !important;
}

.btn-secondary:disabled {
  color: var(--dark-turquoise);
}

.btn-primary:not(:disabled):hover,
.btn-secondary:not(:disabled):hover,
.btn-secondary:not(:disabled):focus,
.btn-primary:not(:disabled):focus {
  box-shadow: none !important;
  background-color: var(--foodsteps-turquoise) !important;
  border-color: var(--foodsteps-turquoise) !important;
  color: white !important;
}

.LinkButton {
  box-shadow: none !important;
}

.btn-primary,
.btn-secondary {
  height: 40px;
  padding: 8px 16px 8px 16px !important;
}

.btn:disabled,
.btn.disabled {
  opacity: 30% !important;
}
