.password-strength-bar {
  height: 6px;
  border: 0px;
  border-radius: 100px;
}

.password-strength-bar-label {
  flex-shrink: 0;
}

.password-strength-bar--very-strong {
  width: 100%;
  background-color: var(--label-A);
}

.password-strength-bar--strong {
  width: 80%;
  background-color: var(--label-B);
}

.password-strength-bar--average {
  width: 60%;
  background-color: var(--label-C);
}

.password-strength-bar--weak {
  width: 40%;
  background-color: var(--label-D);
}

.password-strength-bar--very-weak {
  width: 20%;
  background-color: var(--label-E);
}
