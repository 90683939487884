.modal-content {
  background-color: var(--white) !important;
  border: none !important;
  border-radius: 8px;
}

.modal-header {
  border-bottom: none !important;
  padding: 1.5rem 2rem 1rem;
}

.modal-body {
  padding: 1rem 2rem;
}

.modal-footer {
  border-top: none !important;
  padding: 0.75rem 2rem 1.5rem;
}

.modal-footer > * {
  margin: 0;
}
