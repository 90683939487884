.ingredient-column {
  width: 45%;
}

.decide-later-cell {
  margin-top: 2px;
  padding-bottom: 6px;
  padding-left: 8px;
  padding-top: 6px;
}

.status-table-row:hover {
  background: #e9ecef;
}

.uploaded-recipes-table-card {
  width: 75%;
  margin-bottom: 6rem;
  padding-bottom: 0.5rem !important;
  padding-top: 0 !important;
}

.DuplicateRecipePromptButton:hover {
  background-color: var(--interface-light-turquoise);
}
