.grid-recipe-status {
  --icon-width: 42px;
  --left-text-padding: 12px;
  display: flex;
}

.grid-recipe-status .icon-wrapper {
  width: calc(var(--icon-width) - var(--left-text-padding));
  margin-top: auto;
  margin-bottom: auto;
}

.grid-recipe-status .icon-wrapper .icon {
  width: var(--icon-width);
}

.grid-recipe-status p {
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 8px;
  color: #000000;
  font-size: 11px;
  inline-size: 74px;
  line-height: 14px;
  margin: 0;
  padding-bottom: 5px;
  padding-top: 5px;
}

.grid-recipe-status .needs-attention {
  padding-left: 8px;
  padding-right: 8px;
}

.grid-recipe-status .internal-error {
  padding-left: 8px;
  padding-right: 8px;
}

.grid-recipe-status .pending,
.grid-recipe-status .unavailable {
  padding-left: 4px;
  padding-right: 4px;
}

.sharing-icon {
  min-height: 22px;
  min-width: 22px;
}

.grid-card {
  min-height: 6.7rem;
}

.ResultsGrid_StaleImpact {
  opacity: 30%;
}

.ResultsGrid_GridItemProgressBarContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  color: black;
}

.ResultsGrid_GridItem_SpinnerContainer {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% + 20px));
}

.ResultsGrid_GridItem_Spinner {
  width: 40px;
  height: 40px;
}
