.table-active-row {
  background-color: var(--interface-light-turquoise);
  border-top: 1px solid var(--foodsteps-turquoise) !important;
  border-bottom: 1px solid var(--foodsteps-turquoise) !important;
}

.table td,
.table th {
  padding: 12px;
}

.table-header-private-icon {
  margin-top: -1px;
  color: black !important;
  opacity: 30%;
  cursor: pointer;
}

.table-header-private-icon:hover {
  color: var(--foodsteps-turquoise) !important;
  opacity: 100% !important;
}
