.file-input-button {
  input {
    width: 10px;
  }

  label {
    position: relative;
    left: -10px;
  }
}
