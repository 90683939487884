// This is mostly copied from Bootstrap so that react-select elements match
// other form controls

// We prefix all of the selectors with .react-select-container to make sure
// we have higher specificity than the classnames generated by react-select.

.react-select-container {
  .react-select__control {
    color: var(--interface-grey);
    background-color: $input-bg;
    height: 40px;
    border: 1px solid var(--input-border);
    border-radius: 8px;

    @include transition($input-transition);

    &:hover {
      color: var(--mid-grey);
      border: 1px solid var(--mid-grey);
    }

    &.react-select__control--is-focused {
      color: var(--dark-grey);
      background-color: $input-focus-bg;
      outline: 0;
      border: 1px solid var(--foodsteps-turquoise) !important;
      box-shadow: 0 2px 10px 0 var(--interface-light-turquoise) !important;
    }
  }

  .react-select__input {
    background-color: $input-bg;
    color: $input-color;
  }

  .react-select__menu {
    // A z-index is chosen for consistency with Bootstrap:
    //
    //     https://getbootstrap.com/docs/4.6/layout/overview/#z-index
    //
    // This prevents issues with other elements, such as custom checkboxes,
    // appearing on top of react-select's menu
    z-index: $zindex-dropdown;
  }

  .react-select__option {
    color: theme-color("info");
    cursor: pointer;
    display: flex;
    //justify-content: space-between;
    margin: 0;

    &.react-select__option--is-disabled {
      background-color: var(--white) !important;
      cursor: default;
      color: rgba(0, 0, 0, 0.3) !important;
    }

    &.react-select__option--is-focused {
      background-color: var(--bone);
      color: color-yiq(#ddd);
    }

    &:hover {
      background-color: var(--bone);
    }

    &.react-select__option--is-selected {
      background-color: var(--foodsteps-turquoise);
      color: white;
    }
  }

  .react-select__single-value {
    background-color: $input-bg;
    max-width: calc(100% - #{$input-padding-x * 2});
  }

  .react-select__value-container {
    padding-left: $input-padding-x;
    padding-right: $input-padding-x;
  }

  .react-select__indicator {
    color: inherit;
  }

  .react-select__dropdown-indicator {
    padding: 8px 12px;
  }

  .react-select__indicator-separator {
    background-color: var(--interface-grey) !important;
  }
}

.react-select-container.is-invalid {
  .react-select__control {
    &.react-select__control--is-focused {
      &,
      &:hover {
        border-color: $form-feedback-invalid-color;
      }
    }

    &,
    &:hover {
      border-color: $form-feedback-invalid-color;
    }
  }

  .react-select__value-container {
    @if $enable-validation-icons {
      padding-right: $input-height-inner;
      background-image: escape-svg($form-feedback-icon-invalid);
      background-repeat: no-repeat;
      background-position: right $input-height-inner-quarter center;
      background-size: $input-height-inner-half $input-height-inner-half;

      .react-select__single-value {
        max-width: subtract(100%, add($input-padding-x, $input-height-inner));
      }
    }
  }

  .react-select__value-container--has-value {
    @if $enable-validation-icons {
      padding-right: $input-height-inner-half;
      background-position: right 0 center;

      .react-select__single-value {
        max-width: subtract(
          100%,
          add($input-padding-x, $input-height-inner-half)
        );
      }
    }
  }
}

.GroupedSelect__group_label {
  text-transform: capitalize;
  color: var(--info);
  font-weight: var(--font-weight-semi-bold);
  font-size: medium;
}

.react-select__multi-value {
  background-color: var(--bone) !important;
  border-radius: 4px !important;
}

.react-select__multi-value__remove {
  background-color: var(--bone) !important;
  color: var(--dark-turquoise);
}

.react-select__multi-value__remove:hover {
  background-color: var(--interface-light-turquoise) !important;
  color: var(--foodsteps-turquoise) !important;
}

.IconLastValue,
.IconFirstValue {
  display: flex;
  align-items: center;
  gap: 8px;
}

.IconFirstValue {
  justify-content: left !important;
}

.IconLastValue {
  justify-content: space-between !important;
  width: 100%;
}
