.ScrollTable {
  overflow: auto;
  border: 1px solid var(--interface-grey);
  border-radius: 8px;
  height: 100%;
  background-color: white;
  z-index: 1; /*Bring forward to hide transition behind*/
}

.ScrollTable thead tr th {
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--interface-grey);
  z-index: 1000;
}

.ScrollTable table {
  border-collapse: separate; /* So that the bottom border stays in place when scrolling */
  border-spacing: 0;
  margin-bottom: 0;
}

.ScrollTable th {
  background: white;
  border-top: none;
  font-weight: var(--font-weight-medium);
  padding: 20px 16px !important;
}

.row__highlighted {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
}

.ScrollTable tr:nth-child(1) td {
  border-top: none;
}

.ScrollTable td {
  padding: 12px 16px !important;
}

.ScrollTable_EmptyStateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 64px;
}

.ManageTagsModal_Body ::-webkit-scrollbar-track {
  margin-top: 64px !important;
}
