body {
  color: black;
  font-family: "Graphik Web" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Graphik Web";
  src: url("../fonts/graphik-web/woff-definitions/Graphik-Semibold-Web.woff2")
      format("woff2"),
    url("../fonts/graphik-web/woff-definitions/Graphik-Semibold-Web.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Semibold-Web {
  font-family: "Graphik Web" !important;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("../fonts/graphik-web/woff-definitions/Graphik-Medium-Web.woff2")
      format("woff2"),
    url("../fonts/graphik-web/woff-definitions/Graphik-Medium-Web.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Medium-Web {
  font-family: "Graphik Web" !important;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("../fonts/graphik-web/woff-definitions/Graphik-Regular-Web.woff2")
      format("woff2"),
    url("../fonts/graphik-web/woff-definitions/Graphik-Regular-Web.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Regular-Web {
  font-family: "Graphik Web" !important;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("../fonts/graphik-web/woff-definitions/Graphik-Light-Web.woff2")
      format("woff2"),
    url("../fonts/graphik-web/woff-definitions/Graphik-Light-Web.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Light-Web {
  font-family: "Graphik Web" !important;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}
