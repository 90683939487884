.EditTagsButton {
  white-space: nowrap;
}

.edit-tag-intro {
  color: var(--text-muted);
}

.edit-tags-modal .ManageTagsButton {
  width: auto;
  height: auto;
  gap: 8px;
}

.edit-tags-modal .modal-body {
  padding-bottom: 64px;
}

.edit-tags-modal .modal-footer > *:first-child {
  margin-right: auto;
}
