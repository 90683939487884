.recipe-query-controls .searchbox form .input-field input {
  background-color: white !important;
  border-color: var(--input-border);
}

.recipe-query-controls .sort-by {
  flex-basis: 180px;
  max-width: 180px !important;
}

.recipe-query-controls .collection-select {
  flex-basis: 160px;
}

.recipe-query-controls .collection-select,
.recipe-query-controls .sort-by {
  max-width: 300px;
  flex-grow: 1;
  flex-shrink: 0;
  white-space: nowrap;
}

/* without this the dropdowns are 2 pixels shorter then the search box */
.recipe-query-controls .collection-select .react-select__control,
.recipe-query-controls .sort-by .react-select__control {
  height: 100%;
}

.recipe-query-controls .react-select__control--is-focused {
  border-color: var(--interface-input-glow) !important;
}

.recipe-query-controls .sort-by .react-select__single-value,
.recipe-query-controls .collection-select .react-select__single-value,
.recipe-query-controls .react-select__placeholder {
  color: var(--info) !important;
}

.recipe-query-controls .react-select__menu-list {
  /* removing the padding is needed so that when the top and bottom items
  have non-white backgrounds there is not a gap of white above/below them */
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-width: fit-content;
}

.recipe-query-controls .react-select__menu {
  min-width: fit-content;
  border-radius: 8px;
}

.recipe-query-controls .react-select__option--is-selected {
  background-color: var(--foodsteps-turquoise) !important;
  color: white !important;
}

.recipe-query-controls .react-select__menu-list:first-child {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.recipe-query-controls .top-filter-row,
.recipe-query-controls .filter-wrapper {
  gap: 16px;
}

.RecipeQueryControls_Disabled {
  pointer-events: none;
  opacity: 30%;
}

.RecipeQueryControls_SystemBoundaryText {
  display: flex;
  height: 42px;
  align-items: center;
}

.RecipeQueryControls_Checkboxes {
  display: flex;
  flex-direction: row;
  margin-top: 26px;
}
