.PasswordStrengthSuggestions {
  max-height: 0;
  opacity: 0;
  padding: 0rem 0.75rem;
  transition: max-height 0.35s ease-out, opacity 0.15s ease-out;
}

.PasswordStrengthSuggestions__visible {
  max-height: 1000px;
  opacity: 1;
  padding: 0rem 0.75rem;
  transition: max-height 0.65s ease-in, opacity 0.25s ease-in;
}
