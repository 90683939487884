.Packaging_Panel {
  display: flex;
  padding: 32px 40px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.Packaging_InstructionsContainer {
  width: 620px;
}

.Packaging_Panel_Title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
