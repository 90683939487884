:root {
  --radio-outline-border-width: 0.08em;
  --radio-button-size: 1.375em;
  --radio-button-dot-size: 0.75em;
}

.radio-button-input ~ label {
  margin-left: var(--checkbox-radio-button-label-space) !important;
}

.radio-buttons-inline-wrapper {
  gap: 1rem;
}

.radio-button-square {
  padding: 1em;
  border: 1px solid var(--input-border);
  border-radius: 6px;
  background-color: white;
}

.radio-button-square-checked {
  border-color: var(--foodsteps-turquoise);
  background-color: var(--interface-light-turquoise);
}

.radio-button-square:hover {
  border-color: var(--foodsteps-turquoise);
  accent-color: var(--foodsteps-turquoise);
}

input[type="radio"].radio-button-input {
  display: grid;
  place-content: center;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  border-width: var(--radio-outline-border-width);
  border-style: solid;
  border-color: var(--input-border);
  border-radius: 50%;
  width: var(--radio-button-size);
  height: var(--radio-button-size);
  /* 1.5em being the line-hight used by the parent div */
  transform: translateY(calc((1.5em - var(--radio-button-size)) / 2));
  background-color: white;
  transition: var(--checkbox-radio-button-transition);
}

input[type="radio"].radio-button-input-inline {
  transform: translateY(0);
}

input[type="radio"].radio-button-input-hover {
  box-shadow: var(--checkbox-radio-button-box-shadow);
  border-color: var(--foodsteps-turquoise);
  cursor: pointer;
}

input[type="radio"].radio-button-input:checked {
  border-color: var(--foodsteps-turquoise);
  box-shadow: var(--checkbox-radio-button-box-shadow);
}

input[type="radio"].radio-button-input::before {
  content: "";
  width: var(--radio-button-dot-size);
  height: var(--radio-button-dot-size);
  border-radius: 50%;
  box-shadow: inset var(--radio-button-size) var(--radio-button-size)
    var(--foodsteps-turquoise);
  transform: scale(0);
}

input[type="radio"].radio-button-input:checked::before {
  transform: scale(1);
}
