.AccountDropdown_Toggle {
  padding: 0 !important;
  box-shadow: none !important;
}

.AccountDropdown_InitialsContainer {
  border-radius: 50%;
  background-color: var(--foodsteps-turquoise);
  color: white;
  width: 40px;
  height: 40px;
  display: inline-block;
}

.AccountDropdown_Initials {
  position: relative;
  top: 0.5rem;
}

.AccountDropdown_Item {
  color: var(--dark-turquoise) !important;
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  font-size: medium !important;
  padding: 12px 16px !important;
}

.AccountDropdown_Item:hover {
  color: var(--foodsteps-turquoise) !important;
  background-color: white;
  cursor: pointer;
  font-weight: var(--font-weight-medium);
}

.AccountDropdown_Divider {
  margin: 4px 16px !important;
}

.AccountDropdown_Menu {
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 var(--drop-shadow-darker) !important;
  padding: 4px 0;
  min-width: 228px;
}
