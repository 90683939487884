.ImpactCategoryToggle {
  z-index: 100;
}

.ImpactCategoryToggle__SymbolToggle__Container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ImpactCategoryToggle__SymbolToggle {
  display: flex;
  box-shadow: none !important;
  padding: 0 !important;
  gap: 6px;
}

.ImpactCategoryToggle__PageToggle {
  display: flex;
  min-width: 160px;
  width: 100%;
  padding: 8px 12px !important;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--input-border);
  background: white;
  color: black;
  box-shadow: none !important;
  justify-content: space-between;
}

.ImpactCategoryToggle__PageToggle__Hover {
  border-color: var(--mid-grey);
}

.ImpactCategoryToggle__PageToggle__Active {
  border-color: var(--foodsteps-turquoise);
}

.ImpactCategoryToggle__Item {
  padding: 8px 12px !important;
}

.ImpactCategoryToggle__Item__First {
  border-radius: 7px 7px 0 0;
}

.ImpactCategoryToggle__Item__Last {
  border-radius: 0 0 7px 7px;
}

.ImpactCategoryToggle__Item:hover {
  background-color: var(--bone);
}

.ImpactCategoryToggle__Item__Active {
  color: white !important;
  background-color: var(--foodsteps-turquoise) !important;
}

.ImpactCategoryToggle .dropdown-toggle::after {
  display: none;
}

.ImpactCategoryToggle__Menu {
  min-width: 160px;
  width: 100%;
  padding: 0;
  border: 1px var(--interface-grey) solid !important;
  border-radius: 8px;
  margin-top: 2px;
}

.ImpactCategoryToggle__Chevron {
  color: var(--input-border);
}

.ImpactCategoryToggle__Chevron__Hover {
  color: var(--mid-grey);
}

.ImpactCategoryToggle__Chevron__Expanded {
  color: var(--dark-grey);
}
