.RecipeBreakdown {
  display: flex;
  justify-content: center;
}

.tooltip-inner {
  max-width: 288px;
}

.RecipeBreakdown_Container {
  padding: 24px 40px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.RecipeBreakdown_UpdatingProductSpinnerCard_Container {
  position: absolute;
  top: 300px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.RecipeBreakdown_UpdatingProductSpinnerCard {
  display: inline-flex;
  padding: 24px 40px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}

.RecipeBreakdown_Stale {
  opacity: 30%;
}
