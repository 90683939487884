.Toast {
  position: absolute;
  top: 14px;
  right: 24px;
  display: flex;
  width: 420px;
  padding: 12px 16px;
  gap: 8px;
  border-radius: 8px;
  background: var(--interface-light-turquoise);
  box-shadow: 0 6px 14px 0 var(--drop-shadow-darker);
  z-index: 999;
}

.Toast_Text {
  width: 100%;
}

.Error-Toast {
  background: #fef0ef;
}

.Error-Toast .Delete__svg {
  fill: var(--danger);
}
