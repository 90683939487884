.tooltip-right {
  padding: 0 0 0 6px !important;
}

.tooltip-top {
  padding: 0 0 6px 0 !important;
}

.tooltip-right,
.tooltip-top > .tooltip-inner {
  background-color: rgba(0, 0, 0, 0.8) !important;
  padding: 8px !important;
  border-radius: 6px;
}

.tooltip-text {
  line-height: 20px !important;
  padding: 0 !important;
  text-align: left !important;
}
