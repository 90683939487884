.footer {
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: var(--mid-grey);
  position: fixed;
  bottom: 0;
  width: 248px;
  background-color: var(--white);
}
