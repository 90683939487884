.download-modal {
  border: 0;
}

.download-modal .modal-body {
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.download-modal .modal-content {
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 56px;
  padding-right: 56px;
}

.download-modal .card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
}

.download-modal .label-card {
  border-radius: 8px !important;
  height: 232px;
}

.download-modal .card-title {
  display: table-row;
  min-height: 3rem;
}

.download-modal .cancel-button {
  width: calc(50% - 0.5rem);
}

.download-modal .export-button {
  padding-left: 0;
  padding-right: 0;
  min-width: 12rem;
}

.download-modal .modal-button-container {
  gap: 1rem;
}

.DownloadLabelsModal__ImpactCategoryToggle {
  width: 214px;
}

.DownloadLabelsModal__RadioButtons {
  width: 444px;
}
