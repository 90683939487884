.TagMultiSelect_MultiValueContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  padding-right: 8px;
}

.TagMultiSelect .react-select__multi-value {
  margin-left: 0;
  border-radius: 0 6px 6px 0 !important;
}

.TagMultiSelect .react-select__multi-value__label {
  border-radius: 0;
  padding: 4px;
}

.TagMultiSelect_MultiValueContainer_Arrow {
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-right: 9px solid var(--bone);
}

.TagMultiSelect_Chip_Close .react-select__multi-value__remove {
  border-left: 1px solid var(--interface-grey);
  border-radius: 0 6px 6px 0 !important;
  height: 100%;
  padding: 6px 8px 6px 6px;
}

.TagMultiSelect_Chip_Close .react-select__multi-value__remove:hover {
  background-color: var(--bone) !important;
}

.TagMultiSelect_AddLabel {
  font-weight: var(--font-weight-medium);
  display: flex;
  align-items: center;
  gap: 6px;
}

.TagMultiSelect .react-select__control {
  min-height: 40px;
  height: fit-content;
}

.TagMultiSelect .react-select__value-container {
  padding-left: 6px;
}

.TagMultiSelect .react-select__option {
  display: flex;
  padding: 8px 12px 8px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: black !important;
}

.TagMultiSelect .react-select__option--is-selected {
  background-color: var(--interface-light-turquoise) !important;
}

.TagMultiSelect .react-select__option--is-selected:hover {
  background-color: var(--bone) !important;
}

.TagMultiSelect .react-select__menu {
  width: 480px;
  border-radius: 8px;
  max-height: 273px;
}

.TagMultiSelect .react-select__menu-list {
  padding: 0;
  border-radius: 0 0 8px 8px;
  max-height: 208px;
}

.TagMultiSelect_TickContainer {
  min-width: 20px;
}

.TagMultiSelect_NewTagContainer {
  width: 480px;
  height: 57px;
  justify-items: center;
  padding-left: 12px;
  border-radius: 8px 8px 0 0 !important;
  border: 1 solid var(--interface-grey) !important;
}

.TagMultiSelect_NewTagTextContainer {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  height: 100%;
}

.TagMultiSelect_Divider {
  margin: 0 12px 4px 12px;
}
