.ManageTagsModal {
  display: flex !important;
}

.ManageTagsModal .modal-dialog {
  align-self: center;
  display: flex;
  width: 880px !important;
}

.ManageTagsModal_EmptyState_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.ManageTagsModal_EmptyState_TextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.ManageTagsModal_EmptyState_Heading {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: var(--font-weight-medium);
}

.ManageTagsModal_EmptyState_Instructions {
  color: var(--text-muted);
}

.ManageTagsModal_Controls {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
}

.ManageTagsModal .modal-body {
  padding: 0 40px 24px 40px;
}

.ManageTagsModal_Body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 400px;
}

.ManageTagsModal_NewTagEditor {
  border-radius: 8px;
  border: 1px solid var(--interface-grey);
  display: flex;
  padding: 12px 16px 12px 16px;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.ManageTagsModal_ExistingTagEditor {
  display: flex;
  padding: 8px 0 4px 0;
  justify-content: space-between;
  align-items: flex-end;
  overflow: visible;
  width: 766px;
  /*A hack because firefox won't give any space to the scrollbar*/
  -webkit-padding-end: 12px;
}

.ManageTagsModal__TagName {
  max-width: 375px !important;
}

.ManageTagsModal_TagEditor_TagNameField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.ManageTagsModal_TagEditor_Buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.ManageTagsModal_TagEditor_TextField {
  width: 420px;
}

.ManageTagsModal_ManageTagsModal_PrimaryButton {
  line-height: 20px !important;
}

.ManageTagsModal__RecipeCount {
  padding-top: 80px !important;
}
