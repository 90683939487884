.PackagingComponentTable__table hr {
  margin: 0;
}

.PackagingComponentTable__cell {
  color: var(--info);
  height: inherit;
  line-height: 4rem;
  overflow: hidden;
}

.PackagingComponentTable__header {
  justify-items: start;
  color: var(--info);
  line-height: 1rem;
  padding: 2rem 1rem 1rem 1rem;
}

.PackagingComponentTable__row {
  height: 4rem;
  padding: 0 1rem 0 1rem;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.15s ease-out;
}

.PackagingComponentTable__row__highlighted {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.075);
  transition: background-color 0.25s ease-in;
}

.PackagingComponentTable__column__name {
  width: 40%;
  overflow: hidden;
}

.PackagingComponentTable__column__name__cell {
  transition: color 0.15s ease-out;
}

.PackagingComponentTable__column__name__cell__highlighted {
  color: var(--foodsteps-turquoise) !important;
  transition: color 0.25s ease-in;
}

.PackagingComponentTable__column__name__cell__link {
  color: var(--dark-turquoise);
}

.PackagingComponentTable__column__name__cell__edit {
  opacity: 0;
  transition: opacity 0.15s ease-out;
}

.PackagingComponentTable__column__name__cell__edit__highlighted {
  opacity: 1;
  transition: opacity 0.25s ease-in;
}

.PackagingComponentTable__column__materials {
  width: 40%;
}

.PackagingComponentTable__column__materials__singleMaterial {
  margin-right: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.PackagingComponentTable__column__impact {
  width: 8%;
  text-align: right;
}

.PackagingComponentTable__column__impact__no_delete_column {
  width: 10% !important;
}

.PackagingComponentTable__header__overflow__left {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  overflow: visible;
}

.PackagingComponentTable__column__weight {
  width: 8%;
  overflow: hidden;
  text-align: right;
  padding-left: 0.5rem;
}

.PackagingComponentTable__column__weight__no_delete_column {
  width: 10% !important;
}

.PackagingComponentTable__column__delete {
  width: 4%;
}
