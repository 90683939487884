.HighestAndLowestImpactProductsCard {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}

.HighestOrLowestProductImpactCard {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 12px;
}

.HighestOrLowestProductImpactCard__ViewMoreLinkContainer {
  display: flex;
  justify-content: right !important;
}

.HighestOrLowestProductImpactCard__ViewMoreLink {
  display: flex;
  gap: 6px;
  padding: 0 !important;
  align-items: center !important;
}

.ProductImpactCard {
  display: grid;
  grid-template-rows: repeat(2, auto);
  padding: 16px 16px 12px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: var(--bone);
}

.ProductImpactCard__RecipeLink {
  display: flex;
  height: 48px;
  padding: 0 !important;
  text-align: start !important;
  vertical-align: top !important;
}

.ProductImpactCard__ImpactInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ProductImpactCard__ImpactInfo__ValueAndUnit {
  display: grid;
  grid-template-rows: repeat(2, auto);
  text-align: left;
}
.ProductImpactCard__ImpactInfo__value {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
}
.ProductImpactCard__ImpactInfo__unit {
  line-height: 20px;
}
.ProductImpactCard__ImpactInfo__icon {
  width: 46px;
}
