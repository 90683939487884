.ProductsByDietChart {
  display: grid;
  gap: 16px;
  grid-template-rows: min-content 1fr;
}

.ProductsByDietChart_Tooltip {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 4px;
  padding: 8px;
}

.ProductsByDietChart_Tooltip_Label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.ProductsByDietChart_Tooltip_ItemCount {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  gap: 4px;
  white-space: nowrap;
  align-items: baseline;
}

.ProductsByDietChart_Tooltip_ItemCountValue {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
}

.ProductsByDietChart_Tooltip_PercentageProductRange {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  gap: 4px;
  white-space: nowrap;
}

.ProductsByDietChart_Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
