.UpgradeRequestModal {
  border: 0;
  display: flex !important;
}

.UpgradeRequestModal .modal-content {
  align-self: center;
  border-radius: 8px;
  gap: 32px;
  margin: auto;
  padding: 32px 40px 32px 40px;
  width: 880px !important;
}

.UpgradeRequestModal .header-and-copy {
  display: inline-grid;
  grid-row-gap: 17px;
}

.UpgradeRequestModal .modal-header {
  align-items: center;
  height: 40px;
  justify-content: space-between;
  text-align: center;
}

.UpgradeRequestModal .title-and-icon {
  display: flex;
  gap: 8px;
}

.UpgradeRequestModal .plans-icon {
  margin-top: 6px;
}

.UpgradeRequestModal .modal-body {
  padding: 0;
}

.UpgradeRequestModal .modal-form-group {
  display: inline-grid;
  gap: 24px;
  width: 100%;
}

.UpgradeRequestModal .modal-input-group {
  display: inline-grid;
  gap: 8px;
}

.UpgradeRequestModal .small-form-input,
.large-form-input {
  border: solid 1px #ced4da;
  border-radius: 8px;
  padding: 8px 12px 8px 12px;
  resize: none;
}

.UpgradeRequestModal .small-form-input {
  height: 40px;
  line-height: 22px;
  width: 70%;
}

.UpgradeRequestModal .large-form-input {
  height: 140px;
}

.UpgradeRequestModal .small-form-input:focus-visible,
.large-form-input:focus-visible {
  border: solid 1px var(--foodsteps-turquoise);
  box-shadow: 0 0 6px 0 var(--foodsteps-turquoise);
  outline: none;
}

.UpgradeRequestModal .modal-footer {
  justify-content: flex-start;
  padding: 0;
}

.UpgradeRequestModal .buttons {
  display: flex;
  gap: 16px;
}
