.DashboardOrganizationFilter_Menu {
  background-color: white;
  border: 1px solid var(--interface-grey);
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 var(--drop-shadow-darker) !important;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 8px;
  right: 200px;
  width: 440px;
}

.DashboardOrganizationFilter_Menu_Header {
  display: flex;
  padding: 16px 24px 8px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.DashboardOrganizationFilter_Menu_Search {
  display: flex;
  padding: 8px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.DashboardOrganizationFilter_Menu_Organization {
  display: flex;
  padding: 8px 8px 8px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.DashboardOrganizationFilter_Menu_Controls {
  display: flex;
  padding: 8px 24px 16px 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.DashboardOrganizationFilter_Divider {
  color: var(--mid-grey);
  height: 1px;
  margin: 4px 24px 4px 24px;
}

.DashboardOrganizationFilter_Menu_Search {
  display: inline;
}

.DashboardOrganizationFilter_Menu_RadioButtons {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.DashboardOrganizationFilter_Option {
  display: flex;
  padding: 8px 8px 8px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.DashboardOrganizationFilter_Option_Label {
  margin: auto;
}

.DashboardOrganizationFilter_Option__checked {
  background-color: var(--interface-light-turquoise);
}

.DashboardOrganizationFilter_Option__hover {
  cursor: pointer;
  background-color: var(--bone);
}
