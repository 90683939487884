.DeleteModal {
  backdrop-filter: brightness(50%);
}

.DeleteModal .modal-dialog {
  width: 630px !important;
}

.DeleteModal_Form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.DeleteModal_Buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.DeleteModal_ErrorAlert {
  margin-top: 12px;
}
