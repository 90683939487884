.PhysicalInputEditor_NameContainer {
  margin-right: auto;
}

.PhysicalInputEditor_QuantityContainer {
  width: 174px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.PhysicalInputEditor_LossPercentageContainer {
  width: 134px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
}
