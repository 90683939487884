.no-radius-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.no-radius-top {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.no-radius-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
