.CalculationTable__Tabs {
  color: var(--interface-grey);
}

.GhgTableData_per_amount {
  background-color: var(--light-accent-yellow);
}

.GhgTableData_per_kg {
  background-color: var(--pastel-pink);
}

.GhgTableData_per_serving {
  background-color: var(--light-marooned);
}

.LandUseTableData_per_amount {
  background-color: var(--interface-grey);
}

.LandUseTableData_per_kg {
  background-color: var(--mint-pastel);
}

.WaterUseTableData_per_amount {
  background-color: var(--aqua);
}

.WaterUseTableData_per_kg {
  background-color: var(--light-marine);
}

.CalculationTable td {
  padding: 8px;
}

.CalculationTable th {
  padding: 8px;
}

.CalculationTable {
  width: min-content;
}

.CalculationTable__Controls {
  --checkbox-size: 1rem;
}
