.Plans__pill {
  border-radius: 100px;
  background-color: var(--foodsteps-turquoise);
  color: white;
}

.billing-interval-toggle-button {
  width: 126px;
}

.my-plan {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 28px;
  position: absolute;
  top: calc(-14px - 1px);
  /* Shift up by half the height and half the border thickness */
  left: calc(50% - 100px / 2);
}

.my-plan-header-row {
  height: 40px;
}

.active-plan-middle {
  border-left: 2px solid var(--foodsteps-turquoise);
  border-right: 2px solid var(--foodsteps-turquoise);
}

.active-plan-top {
  border-left: 2px solid var(--foodsteps-turquoise);
  border-right: 2px solid var(--foodsteps-turquoise);
  border-top: 2px solid var(--foodsteps-turquoise);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.active-plan-bottom {
  border-left: 2px solid var(--foodsteps-turquoise);
  border-right: 2px solid var(--foodsteps-turquoise);
  border-bottom: 2px solid var(--foodsteps-turquoise);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.plans {
  position: relative;
  top: -24px;
}

.our-plans-section {
  position: relative;
  top: -40px;
}

.currency-select-container {
  width: 120px;
}
