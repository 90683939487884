.name-and-email {
  max-lines: 1;
  overflow: hidden;
  min-width: 210px;
  height: 40px;
  text-align: right;
  margin-left: auto;
  margin-right: 0;
}

.Header_SplitRow {
  border-bottom: 1px solid var(--interface-grey);
  height: 72px;
  min-height: 72px;
}
