.PackagingComponentEditor_Container {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.PackagingComponentEditor_Select {
  width: 440px;
}

.PackagingComponentEditor_Text {
  width: 74px;
}

.PackagingComponentEditor_MultiplesOfProductEditor {
  width: 151px;
}

.PackagingComponentEditor_FloatEditorAndDeleteContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
