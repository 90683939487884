.PhysicalOutputsEditor_Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.PhysicalOutputsEditor_RowContainer {
  display: flex;
  flex-direction: row;
  max-width: 650px;
  align-items: center;
  gap: 8px;
}

.PhysicalOutputsEditor_Inputs {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.PhysicalOutputEditor_WeightInput {
  width: 134px;
}

.PhysicalOutputEditor_TextInput {
  width: 322px;
}

.PhysicalOutputEditor_EconomicValueEditor {
  width: 134px;
}

.PhysicalOutputEditor_LossPercentageEditor {
  width: 134px;
}

.PhysicalOutputEditor_DeleteColumn {
  width: 20px;
}

.PhysicalOutputEditor_CenteredSubheading {
  text-align: center;
  font-weight: var(--font-weight-medium);
}

.FinalProductEditor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
