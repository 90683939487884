.RecipeIngredientsEditor_RecipeOrIngredientSelect_Long {
  width: 440px;
}

.RecipeIngredientsEditor_RecipeOrIngredientSelect_Short {
  width: 390px;
}

.RecipeIngredientsEditor_IngredientOrProductSelect {
  width: 174px;
}

.private-icon {
  cursor: pointer;
  opacity: 30%;
}

.private-icon:hover {
  color: var(--foodsteps-turquoise) !important;
  opacity: 100% !important;
}
