.ManageTagsButton,
.ManageTagsButton__WithText {
  display: flex;
  align-items: center;
  justify-items: center;
}

.ManageTagsButton {
  width: 40px;
  height: 40px;
  padding: 9px !important;
}

.ManageTagsButton__WithText {
  gap: 4px;
  padding: 6px 10px !important;
  line-height: 20px;
  text-align: center;
}
