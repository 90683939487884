.SystemBoundaryDisplay_Card {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--bone);
}

.SystemBoundaryDisplay_Inactive {
  opacity: 16%;
}
